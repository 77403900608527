import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import classes from "./footer.module.scss"

import logo from "../assets/logowhite.png"

export default function Footer() {
  let data = useStaticQuery(graphql`
    query footerQuery {
      datoCmsSiteConfig {
        footerTitle
        facebookUrl
        linkedinUrl
        twitterUrl
        youtubeUrl
        copywriteNotice
        footerIntegrations {
          image {
            url
          }
        }
      }
    }
  `)

  const footerData = data.datoCmsSiteConfig
  const {
    footerTitle,
    facebookUrl,
    linkedinUrl,
    twitterUrl,
    youtubeUrl,
    footerIntegrations,
    copywriteNotice
  } = footerData
  return (
    <footer className={classes.footer}>
      <h3>{footerTitle}</h3>
      <div className={classes.logos}>
        {footerIntegrations.map(integration => {
          return (
            <img
              alt="footer-img"
              src={integration.image.url}
              key={integration.image.url}
            />
          )
        })}
      </div>

      <div className={classes.linkscontainer}>
        <div>
          <Link className={classes.link} to="/merchant-pricing">
            Pricing
          </Link>
          <Link className={classes.link} to="/faqs-merchant">
            Merchant FAQs
          </Link>
          <Link className={classes.link} to="/contact">
            Contact
          </Link>
          {/* <Link className={classes.link} to="/pages/customercare">
            Customer Care
          </Link> */}
        </div>
        <div>
          <Link className={classes.link} to="/disclaimer">
            Disclaimer
          </Link>
          <Link className={classes.link} to="/privacy-policy">
            Privacy policy
          </Link>
          <Link className={classes.link} to="/cookie">
            Cookie policy
          </Link>
        </div>
      </div>
      <div className={classes.socials}>
        {facebookUrl && (
          <a href={facebookUrl} target="_blank" rel="noreferrer">
            <FontAwesomeIcon
              className={classes.icon}
              icon={["fab", "facebook"]}
            />
          </a>
        )}
        {twitterUrl && (
          <a href={twitterUrl} target="_blank" rel="noreferrer">
            <FontAwesomeIcon
              className={classes.icon}
              icon={["fab", "twitter"]}
            />
          </a>
        )}
        {linkedinUrl && (
          <a href={linkedinUrl} target="_blank" rel="noreferrer">
            <FontAwesomeIcon
              className={classes.icon}
              icon={["fab", "linkedin"]}
            />
          </a>
        )}

        {youtubeUrl && (
          <a href={youtubeUrl} target="_blank" rel="noreferrer">
            <FontAwesomeIcon
              className={classes.icon}
              icon={["fab", "youtube"]}
              style={{ color: "#FFF" }}
            />
          </a>
        )}
      </div>
      <span>{copywriteNotice}</span>
      <img alt="logo" className={classes.Logo} src={logo} />
    </footer>
  )
}
