import React from "react"
import Header from "./header"
import Footer from "./footer"
import SEO from "./Seo"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import classes from "./layout.module.scss"
import CookiePolicy from "./CookiePolicy/Index"
import "../styles/index.scss"

library.add(fab)

export default function Layout({ pageTitle, children }) {
  return (
    <>
      <Header />
      <CookiePolicy />
      <SEO pageTitle={pageTitle} />
      <div className={classes.container}>{children}</div>
      <Footer />
    </>
  )
}
