import React, { useState } from "react"
import { Link } from "gatsby"
import classes from "./menu.module.scss"
import IconButtons from "./Button/IconButtons"
export default function Menu(props) {
  const [setActiveMerchants, setActiveStateMerchants] = useState("")
  const [setActiveCustomers, setActiveStateCustomers] = useState("")

  function toggleMerchantSubmenuMobile() {
    if (window.innerWidth < 1212) {
      setActiveStateMerchants(setActiveMerchants === "" ? classes.active : "")
    }
  }

  function toggleCustomerSubmenuMobile() {
    if (window.innerWidth < 1212) {
      setActiveStateCustomers(setActiveCustomers === "" ? classes.active : "")
    }
  }

  return (
    <menu className={props.className}>
      <span className={classes.features}>
        <span
          onClick={toggleMerchantSubmenuMobile}
          onKeyDown={toggleMerchantSubmenuMobile}
          role="button"
          tabIndex={0}
        >
          For Merchants
        </span>
        <div className={`${setActiveMerchants}`}>
          <Link to="/merchant" className={classes.featuresList}>
            Merchant Portal
          </Link>
          <Link to="/faqs-merchant" className={classes.featuresList}>
            FAQs
          </Link>
          <Link to="/documentation" className={classes.featuresList}>
            Documentation
          </Link>
          <Link
            to="https://merchants.bolttechecommerce.com/app/login"
            className={classes.featuresList}
          >
            Sign In
          </Link>
        </div>
      </span>
      <span className={classes.features}>
        <span
          onClick={toggleCustomerSubmenuMobile}
          onKeyDown={toggleMerchantSubmenuMobile}
          role="button"
          tabIndex={0}
        >
          For Customers
        </span>
        <div className={`${setActiveCustomers}`}>
          <Link to="/device" className={classes.featuresList}>
            Extended Warranties
          </Link>
          <Link to="/faqs-customer" className={classes.featuresList}>
            FAQs
          </Link>
          <Link to="/service-request" className={classes.featuresList}>
            Service Request
          </Link>
          {/* <Link to="/pages/customercare" className={classes.featuresList}>
            Customer Care
          </Link> */}
        </div>
      </span>
      <Link to="/about-us" activeClassName={classes.active_link}>
        About Us
      </Link>
      <Link to="/blog" activeClassName={classes.active_link}>
        Blog
      </Link>
      <Link to="/contact" activeClassName={classes.active_link}>
        Contact
      </Link>
      <Link to="/contact" className={classes.signmebtn}>
        <IconButtons>Request a Demo</IconButtons>
      </Link>
    </menu>
  )
}
