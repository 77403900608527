import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
    button: {
        flexShrink:0,
        margin: theme.spacing(1),
        backgroundColor: "#17124E",
        borderRadius: "50px",
        padding: "7px 12px",
        textTransform: "unset",
        "&:hover": {
            backgroundColor: "#17124E",
        },
        "& .MuiButton-label": {
            color: "white",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "23px",
        },
        "& .MuiButton-endIcon": {
            color: "#02B9C7",
            "& svg": {
                color: "#02B9C7",
                "& path": {
                    color: "#02B9C7",
                }
            }
        }
    },
}));

export default function IconButtons(props) {
    const classes = useStyles();

    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<ArrowForwardIosIcon></ArrowForwardIosIcon>}
            {...props}
        >
            {props.children}
        </Button>

    );
}