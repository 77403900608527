import React from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"

const useStyles = makeStyles(theme => ({
  button: {
    marginBottom: "10px",
    backgroundColor: "#17124E",
    borderRadius: "0px",
    padding: "14px 36px",
    textTransform: "unset",
    "&:hover": {
      backgroundColor: "#17124E"
    },
    "& .MuiButton-label": {
      color: "white",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "22px"
    },
    "& .MuiButton-endIcon": {
      color: "#02B9C7",
      "& svg": {
        color: "#02B9C7",
        "& path": {
          color: "#02B9C7"
        }
      }
    }
  }
}))

export default function IconButtons(props) {
  const classes = useStyles()

  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.button}
      endIcon={<ArrowForwardIosIcon></ArrowForwardIosIcon>}
      {...props}
    >
      {props.children}
    </Button>
  )
}
