import React, { useEffect, useState } from "react"
import classes from "./index.module.scss"
import { Link } from "gatsby"
import Button from "./PrivacyButton"
import Icon from "./icon.svg"
import { setCookieConsent, getCookieConsent } from "./localStorage"

const CookiePolicy = () => {
  const [atTop, setAtTop] = useState(false)
  const [cookieConsent, setCookieConsentState] = useState(true)

  const handleCookieConsent = () => {
    setCookieConsentState(true)
    setCookieConsent()
  }
  const listner = () => {
    if (window.scrollY <= 20 && window.innerWidth <= 800) {
      setAtTop(true)
    } else {
      setAtTop(false)
    }
  }

  useEffect(() => {
    setCookieConsentState(getCookieConsent())
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", listner)
    return () => {
      window.removeEventListener("scroll", listner)
    }
  })

  return cookieConsent ? (
    <div className="cookie-consent" style={{ display: "none" }} />
  ) : (
    <div
      className={classes.root}
      style={{
        marginTop: atTop ? 125 : 0
      }}
    >
      <div className={classes.container}>
        <img src={Icon} alt="cookie-policy-icon" className={classes.logo} />
        <div class="text-block-module--text-block--3_FBY text-xl font-light text-primary">
          <p>
            We use cookies and log files to track user information. By
            continuing to use our site you agree to us using cookies in
            accordance with our <Link to="/privacy-policy">Cookie Policy.</Link>
          </p>
        </div>
        <Button
          onClick={() => {
            handleCookieConsent()
          }}
        >
          Accept & Close
        </Button>
      </div>
    </div>
  )
}

export default CookiePolicy
