export const getCookieConsent = () => {
  if (typeof window === "undefined") {
    return true;
  }

  return localStorage.getItem("cookie-consent") ? true:false;
};

export const setCookieConsent = () => {
  if (typeof window === "undefined") {
    return;
  }
  return localStorage.setItem("cookie-consent", true);
};
