import React, { useState } from 'react';
import logo from '../assets/logo.png';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import classes from './header.module.scss';
import Menu from './menu';

import { Link } from 'gatsby';

export default function Header() {

    const [menu, setMenu] = useState(false);
    return (
        <header >
            <Link to='/' ><img src={logo} className={classes.headerLogo} alt="logo" /></Link>

            {menu ? <ClearIcon className={classes.menuIcon} onClick={() => { setMenu(!menu) }} /> : <MenuIcon className={classes.menuIcon} onClick={() => { setMenu(!menu) }} />}
            {menu ? <Menu className={classes.mobileHeader} /> : null}
            <div className={classes.desktopheader}>
                <Menu />
            </div>
        </header>
    )
}