import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";

const SEO = ({ pageTitle }) => {
  const data = useStaticQuery(graphql`
    {
      datoCmsSite {
        globalSeo {
          siteName
          titleSuffix
          twitterAccount
          facebookPageUrl
          fallbackSeo {
            title
            description
            image {
              url
            }
            twitterCard
          }
        }
        faviconMetaTags {
          tags
        }
      }
    }
  `);

  const { title, description } = data.datoCmsSite.globalSeo.fallbackSeo;

  return (
    <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags}>
      <title>
        {pageTitle ? `${pageTitle} - ` : ""}
        {title}
      </title>
      <meta name="description" xontent={description} />
    </HelmetDatoCms>
  );
};

export default SEO;
